@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 2px;
        background: #e1faff;
    }

    ::-webkit-scrollbar-thumb {
        background: #189ab4;
        border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #189ab4;
    }

    .table-scrollbar::-webkit-scrollbar {
        width: 4px;
        height: 10px;
    }

    .table-scrollbar::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #e1faff;
    }

    .table-scrollbar::-webkit-scrollbar-thumb {
        background: #05445e;
        border-radius: 10px;
    }

    .table-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #05445e;
    }
}

@layer components {
    .container {
        @apply 3xl:max-w-7xl mx-auto;
    }

    .flex-center {
        @apply flex items-center justify-center;
    }

    .input-rounded {
        @apply rounded-[3px];
    }

    .input-disabled {
        @apply bg-disabled focus:ring-0 !important;
    }

    .input {
        @apply block w-full placeholder:py-[1px] focus:outline-none font-300 input-rounded text-[16px] text-txt font-300 mt-[15px];
    }

    .input-primary {
        @apply input border-primary placeholder-txt-disabled focus:ring-primary focus:border-primary;
    }

    .input::placeholder {
        line-height: 24px;
        font-size: 16px;
    }

    .input-invalid {
        @apply input border-danger placeholder-txt-disabled focus:ring-danger focus:border-danger;
    }

    .input-label {
        @apply font-medium text-body-2 text-txt;
    }

    .select {
        @apply bg-white border input-rounded border-primary ltr:pl-[15px] rtl:pr-[15px];
    }

    .select-input {
        @apply select relative w-full h-[42px] ltr:text-left rtl:text-right cursor-default focus:outline-none focus:ring-1 text-input text-txt font-300;
    }

    .select-options-container {
        @apply select text-caption-1 font-400;
        @apply absolute z-10 mt-1 w-full bg-white shadow-lg py-1 ring-0 overflow-auto focus:outline-none;
        @apply px-[20px] py-[15px];
    }

    .select-primary {
        @apply select-input;
        @apply focus:ring-primary focus:border-primary border-primary;
    }

    .select-invalid {
        @apply select-input;
        @apply focus:ring-danger focus:border-danger border-danger;
    }

    .smooth-scroll {
        scroll-behavior: smooth;
    }

    .layout-container-px {
        @apply px-[10px] md:px-[24px] 2xl:px-[34px] 3xl:px-[58px];
    }

    .sidebar-item-space {
        @apply ltr:pl-[28px] ltr:2xl:pl-[28px] ltr:3xl:pl-[52px];
        @apply rtl:pr-[28px] rtl:2xl:pr-[28px] rtl:3xl:pr-[52px];
    }

    .sidebar-title-text-size {
        @apply ltr:text-[20px] ltr:leading-[24px] ltr:font-500 rtl:leading-[26px] rtl:font-normal rtl:text-[20px];
        @apply ltr:md:text-[26px] ltr:md:leading-[32px] rtl:md:text-[30px] rtl:md:leading-[42px]
    }

    .home-card-shadow {
        box-shadow: 0 26px 35px -30px rgba(0, 0, 0, 0.1);
    }

    .home-card-shadow-rounded {
        box-shadow: 0 10px 40px -30px rgba(0, 0, 0, 0.26);
        @apply rounded-[2px] bg-white border-t-[3px] border-brand;
    }

    .home-wrap {
        @apply w-full px-[10px] ltr:md:px-[24px] rtl:md:px-[24px] ltr:lg:px-[70px] rtl:lg:px-[70px] mx-auto;
    }

    .home-subtitle {
        @apply ltr:font-Harietta text-secondary font-700;
        @apply text-[22px] ltr:leading-[32px] rtl:leading-[40px];
        @apply md:text-[30px] md:ltr:leading-[38px] md:rtl:leading-[46px];
        @apply 2xl:text-[40px] 2xl:ltr:leading-[50px] 2xl:rtl:leading-[56px];
    }

    .home-title {
        @apply home-subtitle;
        @apply text-[28px] ltr:leading-[34px] rtl:leading-[34px];
        @apply md:text-[40px] md:ltr:leading-[50px] md:rtl:leading-[50px];
    }

    .user-dropdown-item {
        @apply block px-1 py-[7px] text-[14px] cursor-pointer hover:text-primary hover:font-medium;
    }

    table tr {
        @apply border-t-brand border-t-[1px];
    }

    table tr.expanded {
        @apply border-t-brand border-t-0;
    }

    table thead tr {
        @apply border-t-[0px];
    }

    table th {
        @apply font-500 text-[16px] leading-[20px] text-secondary whitespace-nowrap py-[20px] ltr:text-left rtl:text-right;
    }

    .title-modal {
        @apply text-[22px] leading-[27px] font-600 text-primary text-center mt-[14px] md:text-[26px] md:leading-[32px] md:mt-[24px];
    }

    .stepper-title {
        @apply title-modal;
    }
}

.slick-dots.how-slick-dots {
    @apply bottom-[-8px] ltr:right-[-75px] rtl:right-[60px];
    @apply xs:rtl:right-[130px];
    @apply sm:rtl:right-[190px];
    @apply md:ltr:right-[-35%]  md:rtl:right-[-130px];
    @apply lg:ltr:right-[-40%] lg:ltr:bottom-[25px] lg:rtl:bottom-[20px] lg:rtl:right-[-200px];
    @apply xl:ltr:bottom-[25px] xl:rtl:bottom-[25px] xl:rtl:right-[-300px];
    @apply 2xl:rtl:bottom-[28px] 2xl:rtl:right-[-310px];
}

.slick-dots.how-slick-dots li,
.slick-dots.testimonials-slick-dots li {
    margin: 0 10px 0 0;
    width: 30px;
}

.how-slick-dots .slick-active > div,
.testimonials-slick-dots .slick-active > div {
    @apply bg-primary;
}

.testimonials-slick .testimonials-slick-prev,
.testimonials-slick .testimonials-slick-next {
    top: 40%;
    @apply bg-primary w-[40px] h-[40px] z-10;
}

.testimonials-slick .testimonials-slick-prev.slick-prev {
    @apply right-auto left-[-10px] md:left-[40px] lg:left-[5px];
}

.testimonials-slick .testimonials-slick-next.slick-next {
    @apply left-auto right-[-10px] md:right-[40px] lg:right-[5px];
}

.testimonials-slick .testimonials-slick-prev.slick-prev:before,
.testimonials-slick .testimonials-slick-next.slick-next:before {
    content: none;
}

.testimonials-slick .testimonials-slick-prev:hover,
.testimonials-slick .testimonials-slick-next:hover {
    @apply bg-primary;
}
